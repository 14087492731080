import 'bulma-pro/css/bulma.css';
import React from "react";

function App() {
  const isMobile = window.innerWidth <= 768;
  const betaj = <sup style={{
    backgroundColor: "yellow",
    fontSize: isMobile ? "5.5pt" : "8pt",
    color: "#222",
    paddingTop: isMobile ? 2 : 3,
    paddingLeft: isMobile ? 2 : 3,
    paddingRight: isMobile ? 2 : 3,
    paddingBottom: isMobile ? 1 : 2,
    marginLeft: isMobile ? 1 : 3,
  }}
  >BETA</sup>;

  return (
    <div className="App">
      <div className="content" style={{
        padding: isMobile ? 0 : "0% 20% 0% 20%"
      }}>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <h1 className={`title ${isMobile ? "is-4" : "is-1"}`} style={{
                // paddingLeft: "20%", 
                paddingTop: "2%",
                opacity: 0.62
              }}>
                Only You Can See This
              </h1>
            </p>
          </header>

          <div className="card-content"
            style={{
              // paddingLeft: "20%", paddingRight: "20%"
            }}
          >
            <h3 className={`title ${isMobile ? "is-4" : "is-3"} has-text-right`} style={{
              paddingRight: isMobile ? 0 : 13, opacity: 0.62
            }}>
              Only You Can See This<br />
              is a UK-based Software Development Group<br />
              operating in the personal health<br />&amp; productivity space.
            </h3>
            <h4 className={`title ${isMobile ? "is-6" : "is-4"}`} style={{ paddingTop: 13, opacity: 0.62 }}>
              {/* We run a number of services on behalf of public &amp; private sector companies. */}
              Please contact us for additional information.<br />
              We also support public projects including:
              <ul>
                <li style={{ marginBottom: 8 }}
                ><a href="/familpedia/">Familpedia</a>{betaj} Family-based Wikipedia-style websites created from ancestry.com family trees</li>
                <li style={{ marginBottom: 8 }}
                ><a href="https://github.com/JeremyJones/Apachetop/blob/master/apachetop.png"
                  target="_blank" rel="noopener noreferrer">Apachetop</a> Website monitoring tool for Linux</li>
                <li style={{ marginBottom: 8 }}
                ><a href="https://www.rotashift.com/">Rotashift</a>{betaj} Automated Rota Management Service</li>
                <li style={{ marginBottom: 8 }}
                ><a href="/toilet-tracker/">Toilet Tracker</a>{betaj} Health tracking service</li>
                <li style={{ marginBottom: 8 }}
                ><a href="/diary/">Only You Can See This</a>{betaj} Personal social networks</li>
              </ul>
            </h4>
          </div>

          <footer className="card-footer">
            <a
              className="card-footer-item"
              href="https://www.facebook.com/onlyyoucanseet"
              target="blank" rel="noopener noreferrer">Our Facebook page</a>
          </footer>
        </div>
      </div>
    </div>);
}

export default App;
